



















































































































































































































import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IPaginate, IPaymentGwDetails, IStore, ITransactions, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import WidgetMixins from '@/mixins/WidgetMixins';
import DialogMixins from '@/mixins/DialogMixins';
import moment from 'moment';
import qs from 'qs';
const transactionsModule = namespace('transactions');
const organizationModule = namespace('organizations');
const authModule = namespace('auth');
@Component({
  name: 'AllPayments',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    GetDetails: loadView('pages/products/dialogs/GetDetails'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class AllPayments extends mixins(WidgetMixins, DialogMixins) {
  @transactionsModule.Getter('getAllTransactions') transactions!: IPaymentGwDetails[];
  @transactionsModule.Getter('getPagination') pagination!: IPaginate;
  @organizationModule.Getter('getAllOrganizations') organizations!: any[];
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  @transactionsModule.Getter('getTransactionDetails') commissionDetails!: ITransactions;
  @transactionsModule.Getter('getTotalCommission') totalCommission!: number;

  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  statuses: Array<string> = ['ALL', 'COMPLETED', 'PENDING', 'FAILED'];
  query: string = '';
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;

  startDate: string | null = null;
  endDate: string | null = null;
  status: string | null = 'ALL';
  organizationId: string | null = null;

  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Date',
      value: 'createdAt',
    },
    {
      text: 'Amt.',
      value: 'amount',
    },
    {
      text: 'Amt. w/ charges',
      value: 'totalAmount',
    },
    {
      text: '3PP Comm.',
      value: '3ppCommission',
    },
    {
      text: 'Commission',
      value: 'commission',
    },
    {
      text: 'Status',
      value: 'paymentStatus',
    },
  ];

  statusColors: { [key: string]: any } = {
    PENDING: {
      bg: 'rgba(253, 183, 132, 0.41)',
      text: 'rgb(202, 133, 0)',
    },
    FAILED: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },

    SUCCESS: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
    COMPLETED: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
  };

  @Watch('options', {
    deep: true,
  })
  onOptionsChange(paginate: IPaginate): void {
    this.query = `?page=${paginate.page}&size=${paginate.itemsPerPage}${
      this.status !== 'ALL' ? '&status=' + this.status : ''
    }&startDate=${this.startDate}&endDate=${this.endDate}`;
    this.$store.dispatch('transactions/listAllPayments', this.query);
  }

  @Watch('startDate')
  onStartDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), startDate: date })}`;
    this.$store.dispatch('transactions/listAllPayments', this.query);
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), endDate: date })}`;
    this.$store.dispatch('transactions/listAllPayments', this.query);
  }

  @Watch('status')
  onStatusChange(value: string): void {
    console.log(value);
    this.query = `?page=${this.pagination.page}&size=${this.pagination.itemsPerPage}${
      value !== 'ALL' ? '&status=' + this.status : ''
    }${this.organizationId !== null ? '&organizationId=' + this.organizationId : ''}&startDate=${
      this.startDate
    }&endDate=${this.endDate}`;
    console.log({ query: this.query });
    this.$store.dispatch('transactions/listAllPayments', this.query);
  }

  @Watch('organizationId')
  onOrganizationIdChange(organizationId: string): void {
    let opts = qs.stringify({
      page: this.pagination?.page,
      size: this.pagination?.itemsPerPage,
      ...(this.status !== 'ALL' && { status: this.status }),
      ...(organizationId && {
        organizationId,
      }),
      startDate: this.startDate,
      endDate: this.endDate,
    });
    this.query = `?${opts}`;
    this.$store.dispatch('transactions/listAllPayments', `?${opts}`);
  }

  downloadFile(ext: string): void {
    const query = this.query + `&download=true&ext=${ext}`;
    this.$store.dispatch('transactions/listAllPayments', query);
  }

  created(): void {
    this.$store.dispatch('auth/me');

    this.startDate = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');

    if (this.$route.query.page && this.$route.query.size && this.$route.query.ext) {
      this.query = `?${qs.stringify({ ...this.$route.query, startDate: this.startDate, endDate: this.startDate })}`;
      this.$store.dispatch('transactions/listAllPayments', this.query);
    } else {
      this.query = `?page=1&size=15&startDate=${this.startDate}&endDate=${this.endDate}`;
      this.$store.dispatch('transactions/listAllPayments', this.query);
    }
    this.$store.dispatch('organizations/listAllMerchants', `?isPaginated=false`);
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
